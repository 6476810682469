import React from "react"
import { Container, Box } from "@mui/material"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const SEOTEXTAT = () => (
	<>
		<div>
			<p>
				Für alle, die <strong>allerhöchste Sicherheitsstandards</strong> wünschen, sind
				unsere <a href="/c/wandtresore">Wandtresore </a>die ideale Lösung. Sie sind auf den
				ersten Blick nicht sichtbar und so im Mauerwerk verankert, dass es quasi unmöglich
				ist, sie mitzunehmen. Wenn Sie nicht die Möglichkeit haben, einen solchen{" "}
				<a href="/c/wertschutzschraenke">Wertschutzschrank </a> zu installieren – keine
				Sorge. Auch mit unseren <a href="/c/moebeltresore">Möbeltresoren </a>, die Sie
				beispielsweise in einem <a href="/c/schranktresore">Schrank </a> verschrauben
				können, sind Ihre Wertsachen optimal geschützt.
			</p>

			<h4>Sicherheit ist bei Waffen besonders wichtig</h4>
			<p>
				Auch Sportschützen, Jäger oder Polizisten können bei uns{" "}
				<strong>spezielle Waffentresore</strong> kaufen, um sowohl{" "}
				<a href="/c/langwaffenschrank">Langwaffen</a> als auch{" "}
				<a href="/c/kurzwaffentresor">Kurzwaffen</a> zu verwahren. Mit
				<a href="/c/waffenschraenke"> Waffenschränken</a> aus dem Tresoro-Shop lagern Sie
				Schusswaffen gesetzeskonform, einbruchsicher und zuverlässig.
			</p>

			<h4>Dokumente und Daten feuerfest aufbewahren</h4>
			<p>
				Mit <a href="/c/feuerschutztresore">feuerfesten Tresoren</a> schützen Sie wertvolle{" "}
				<a href="/c/feuerfeste-aktenschraenke">Akten</a> und{" "}
				<a href="/c/datentresore">Datenträger</a> nicht nur vor Diebstahl, sondern auch vor
				Brandschäden. Diese Tresore sind{" "}
				<strong>von unabhängigen Instituten zertifiziert</strong> und sorgen für bis zu 180
				Minuten dafür, dass Hitze ihrem Inhalt nichts anhaben kann. Selbstverständlich sind
				sie auch so gebaut, dass kein Löschwasser eindringen kann.
			</p>

			<h4>Tresor kaufen bei Tresoro: Die beste Wahl</h4>
			<p>
				Beim Kauf eines Tresors nimmt man nicht einfach irgendetwas - die persönlichen
				Wertgegenstände sollen schließlich in größtmöglicher Sicherheit aufbewahrt werden!
				Ob als <a href="/c/dokumententresore">Safe für Dokumente</a>, als Waffenschrank oder
				Mini-Tresor: Bei Tresoro finden Sie eine{" "}
				<strong>große Auswahl an geprüften und zertifizierten Qualitätstresoren </strong>{" "}
				von renommierten <a href="/marken">Tresor-Herstellern</a> wie etwa{" "}
				<a href="/marken/burg-waechter">Burg Wächter</a>.
			</p>

			<h4>Tresore kaufen in Wien, Salzburg, Graz &amp; Co.</h4>
			<p>
				Vom leicht zu verbergenden <a href="/c/bodentresore">Bodentresor</a> oder Wandtresor
				bis zum großen Waffenschrank mit integriertem Munitionssafe: Im Tresoro-Shop können
				Sie für jeden Zweck einen <a href="/c/einbruchsichere-tresore">Tresor</a> kaufen.
				Dass zum Beispiel Waffenschränke der Norm EN 1143-1 entsprechen, ist dabei
				selbstverständlich. Damit Ihr Tresor schnell den Weg an seinen Bestimmungsort
				findet, liefern wir in ganz Österreich <strong>kostenlos bis Bordsteinkante</strong>{" "}
				- egal, ob Sie sich für einen Mini-Tresor mit 1 Kilo Gewicht oder ein Modell mit 2
				Tonnen entscheiden. Auch der Lieferort spielt keine Rolle: Wir liefern Tresore nach{" "}
				<a href="/content/tresor-kaufen-in-wien">Wien</a> ebenso wie nach{" "}
				<a href="/content/tresor-kaufen-in-salzburg">Salzburg</a>,{" "}
				<a href="/content/tresor-kaufen-in-hannover">Hannover</a>,
				<a href="/content/tresor-kaufen-in-graz"> Graz</a> oder in einen ganz anderen Ort .
				Das gilt natürlich genauso für das bei uns erhältliche Tresor-Zubehör wie zum
				Beispiel{" "}
				<a href="/p/zubehoer-safe-dry-entfeuchter-fuer-schraenke-und-tresore">
					Entfeuchter
				</a>
				,{" "}
				<a href="/p/zubehoer-led-tresorleuchte-mit-bewegungsmelder">
					Tresorleuchten mit Bewegungsmelder
				</a>{" "}
				oder <a href="/c/feuerfeste-dokumententaschen">Dokumententaschen</a>. Gegen einen
				geringen Aufpreis liefern wir den Tresor auch direkt an den Ort der Verwendung und
				stellen ihn dort auf bzw. verankern ihn. Sollten Sie nicht zufrieden sein, können
				Sie innerhalb von 14 Tagen von Ihrem Rückgaberecht Gebrauch machen.
			</p>

			<h4>Tresor-Hersteller: Burg Wächter, Secureo und viele mehr</h4>
			<p>
				Das Tresoro-Sortiment umfasst mehr als 1500 Qualitätstresore. Hier finden Sie für
				jeden Zweck und jede Größenordnung ein passendes Modell. Alle Tresormodelle, die Sie
				bei Tresoro kaufen können, werden von unabhängigen Stellen geprüft und kommen von
				international <a href="/marken">renommierten Marken</a>. Hier unsere Anbieter im
				Überblick: <a href="/marken/bordogna">Bordogna S.p.A.</a>,{" "}
				<a href="/marken/burg-waechter">Burg Wächter</a>,{" "}
				<a href="/marken/chubbsafes">Chubb Safes</a> ,
				<a href="/marken/format">Format Tresore</a>,{" "}
				<a href="/marken/mueller-safe">Müller Safe</a>,{" "}
				<a href="/marken/phoenix-safe">Phoenix Safe</a>,{" "}
				<a href="/marken/secureo"> Secureo</a>,{" "}
				<a href="/marken/sentry-safe">Sentry Safe</a>,{" "}
				<a href="/marken/sistec">Sistec Tresore</a>,{" "}
				<a href="/marken/wertheim">Wertheim Tresore</a>. Welche Art Safe zu empfehlen ist,
				hängt dabei ganz davon ab, <strong>welchen Zweck der Tresor haben soll</strong>. In
				einem <a href="/c/wertschutzschraenke">Tresor für Zuhause </a> können Sie
				beispielsweise Ihre Dokumente und Wertgegenstände sichern, während Sie als
				Waffenbesitzer vielleicht Interesse an einem hochwertigen Waffenschrank oder{" "}
				<a href="/munitionsschraenke">Munitionsschrank</a> haben.
			</p>

			<h4>Der Tresor als Waffenschrank</h4>
			<p>
				Vor allem Sportschützen, Jäger und Polizisten dürften sich für Waffenschränke
				interessieren. Sowohl Langwaffen als auch Kurzwaffen müssen schließlich auf{" "}
				<a href="/content/neues-waffengesetz-2017">gesetzeskonforme </a>und einbruchsichere
				Weise verwahrt werden. Der Besitz und das Handling von Waffen aller Art ist eine
				Angelegenheit, mit der viel Verantwortung einhergeht - Sicherheit ist da zweifellos
				ein Muss. Tresoro-Waffenschränke sind <strong>zertifiziert nach EN 1143-1</strong>,
				der Europanorm für alle neu produzierten und im Handel erhältlichen Modelle. Für den
				Fall einer Kontrolle durch die Waffenbehörde tragen die Tresore eine entsprechende
				Prüfplakette. Ebenso wichtig wie der Waffentresor selbst ist übrigens die
				Munitionsbox zur sicheren Verwahrung der Munition im Tresor.
			</p>

			<h4>Feuerfester Tresor für noch mehr Sicherheit</h4>
			<p>
				Ein erheblicher Schaden kann nicht nur durch Einbruch, sondern auch durch Feuer
				entstehen - es sei denn, Sie schützen Ihre Wertgegenstände in einem{" "}
				<a href="/c/feuerfeste-tresore">feuerfesten Tresor</a>! Dabei gibt es verschiedene{" "}
				<a href="/tresor-sicherheitsstufen">Abstufungen von Hitzeschutz</a>. Den höchsten
				Feuerschutz bieten Modelle, bei denen der Tresorinhalt{" "}
				<strong>über 100 Minuten</strong> lang auch bei Temperaturen von{" "}
				<strong>mehr als 1000°C</strong> unversehrt bleibt. Bei einem Dokumentensafe zum
				Beispiel erkennen Sie die Sicherheitsstufe am Zertifikat: P steht für Brandschutz
				von Papierdokumenten, Akten oder Sparbüchern bis zu 150°C. übersteigt die
				Außentemperatur diese Marke, wird das Papier geschwärzt und unleserlich. Das
				Zertifikat DIS wiederum sagt aus, dass Speichermedien wie Disketten, Computerbänder
				oder CDs im Tresor bei bis zu 50°C brauchbar bleiben. Unabhängig vom Hitzegrad ist
				der Inhalt unserer feuerfesten Tresore aber natürlich auch vor Löschwasser und
				-schaum geschützt. Schließlich bringt der beste Brandschutz nur wenig, wenn die
				Löscharbeiten den eigentlichen Schaden verursachen!
			</p>

			<h4>Sicherheitsstufen bei Tresoren</h4>
			<p>
				Man unterscheidet bei Tresoren zwischen verschiedenen{" "}
				<a href="/tresor-sicherheitsstufen">Sicherheitsstufen</a>. Der Sicherheitsgrad
				spielt unter anderem dann eine Rolle, wenn es um Schadensersatz durch die
				Versicherung geht: Die <strong>Klassifizierung des Tresors</strong> entscheidet über
				die maximale Versicherungssumme. Wie widerstandsfähig ein Tresor eingestuft wird und
				mit welcher Rückerstattung man rechnen kann, hängt auch davon ab, ob das Modell
				privat oder gewerblich eingesetzt wird. Achtung: Bei günstigen Tresoren, also bei
				Modellen der Stufe A oder B nach VDMA-24992 (Verband Deutscher Maschinen- und
				Anlagenbauer), haben Sie keinen Versicherungsschutz. Bei einem Tresor der
				Sicherheitsstufe S1 oder S2 nach VdS EN 14450 im Privatgebrauch hingegen haben Sie
				immerhin einen Einbruchsschutz im Wert von bis zu 2500 Euro. Den höchsten Schutz und
				Versicherungssummen von 40.000 bis hin zu 750.000 Euro bieten{" "}
				<strong>Tresore der Sicherheitsstufe 0 bis 6 nach der Norm EN 1143-1</strong> .
				Falls Sie sich noch unsicher sind, welchen Sicherheitsgrad Ihr Tresor idealerweise
				haben sollte, helfen wir Ihnen ganz einfach bei der Suche. Kontaktieren Sie uns dazu
				gerne jederzeit. Eine Entscheidung ist auch gefragt, wenn es um das Format des
				Tresors gehen soll: Wie viel Platz soll Ihr Tresor einnehmen? Möchten Sie darin
				vielleicht sogar nur einzelne Schlüssel und Kreditkarten verwahren? Falls das der
				Fall ist, möchten wir Ihnen gerne unsere kleinsten Multitalente vorstellen: die
				Mini-Tresore.
			</p>

			<h4>Tresore im Kleinformat: der Mini-Tresor</h4>
			<p>
				Wertvolle Besitztümer müssen nicht immer groß sein. Ganz im Gegenteil -
				Kreditkarten, Schlüssel und Co. kommen im Kleinformat und wollen dennoch sicher
				verstaut werden. Für diese Fälle gibt es unsere Mini-Tresore: Hier können Sie
				Dokumente und Wertgegenstände{" "}
				<strong>einbruchsicher und vor allem unauffällig</strong> verwahren. Sie haben dabei
				die Wahl zwischen <a href="/c/steckdosentresore">Steckdosentresoren</a> und{" "}
				<a href="/c/rohrtresore">Rohrtresoren</a>. Beide Varianten haben ein sehr geringes
				Gewicht, das leichteste Modell wiegt sogar nur federleichte 800 Gramm. Am oberen
				Ende der Gewichtsskala liegen Modelle mit 4 Kilogramm. Beide Arten von Mini-Tresoren
				können in der Wand verbaut werden und sind dann nur noch als Safe zu erkennen, wenn
				man genau weiß, wo man suchen muss. In die kleinen Tresore passen je nach Design
				kleine Wertgegenstände, Reisedokumente, Bargeld, Kreditkarten oder Schlüssel. Auch
				leichter Brandschutz ist gegeben. Der große Vorteil dieser Tresormodelle im
				Kleinformat ist die Tatsache, dass sie sehr unauffällig sind. Wer nicht eingeweiht
				ist, wird einen Steckdosen- oder Rohrtresor kaum als solchen erkennen. Das macht den
				Mini-Tresor <strong>weitaus sicherer</strong> als das vermeintliche Geheimversteck
				in der Sockenschublade oder ganz hinten im Kleiderschrank.
			</p>

			<h4>Zahlenschloss und Fingerprintsensor: So sichern Sie Ihren Tresor</h4>
			<p>
				Der Sicherheitsgrad wird bei Tresoren maßgeblich auch durch die{" "}
				<strong>Art der Versperrung</strong> beeinflusst. Die Produkte aus dem
				Tresoro-Sortiment sind standardmäßig mit einem Doppelbartschloss ausgestattet und
				auf Wunsch mit einem alternativem Schloss erhältlich.
			</p>

			<h4>Tresore mit Zahlenschloss</h4>
			<p>
				Bei unseren Tresoren können Sie neben einem Elektronikschloss auch ein{" "}
				<strong>
					mechanisches Zahlenkombinationsschloss oder ein Doppelbartschloss mit zwei
					Schlüsseln{" "}
				</strong>{" "}
				wählen. Optional lassen sich die Tresore auch mit einem zusätzlichen{" "}
				<a href="/c/einbruchsichere-tresore?page=1&menu%5BlockType%5D=Fingerprintschloss">
					Fingerprintsensor{" "}
				</a>{" "}
				ausstatten Ein Fingerabdrucksensor ist äußerst praktisch, da bei dieser Variante ein
				Höchstmaß an Individualität gegeben ist. Natürlich können auch mehrere
				Fingerabdrücke registriert werden, doch abgesehen von den jeweiligen Personen kommt
				so schnell niemand an den Inhalt Ihres Tresors. Plus: Ein Fingerabdruck kann nicht
				„vergessen“ werden - anders als eine Zahlenkombination.
			</p>

			<h4>Kann man einen Tresor knacken?</h4>
			<p>
				Wer überlegt, einen Tresor zu kaufen, stellt sich früher oder später vermutlich auch
				die Frage, ob sich das lohnt. Denn ist es nicht theoretisch möglich,{" "}
				<strong>einen Tresor zu knacken</strong>? Die einfache Antwort lautet: Ja, möglich
				ist es. Bei einem hochwertigen, fest verankerten und geprüften Tresor ist es aber
				auch alles andere als einfach. Tresore von Tresoro sind nicht nur zertifiziert und
				geprüft, sondern werden auch fest in der Wand oder im Boden verankert. Einen solchen
				Tresor schnell zu knacken, wäre aussichtslos, ebenso wie das Vorhaben, den Tresor
				wegzutragen. Die Investition in gute Befestigung und hochwertige Materialien zahlt
				sich also im Einbruchsfall definitiv aus.
			</p>
			<p>
				Vom Familienerbstück bis hin zum wichtigen Geschäftsdokument: Jeder Mensch hat den
				einen oder anderen Gegenstand, den er <strong>besonders gut schützen</strong> will.
				Im Sortiment von Tresoro finden Sie bestimmt genau den Safe, der zu Ihrem
				Verwendungsbudget und in Ihr Budget passt. überzeugen Sie sich einfach selbst und
				nutzen Sie unsere Filter, um Ihren perfekten Tresor zu finden!
			</p>
		</div>
	</>
)

const SEOTEXTDE = () => (
	<>
		<div>
			<p>
				Für alle, die <strong>allerhöchste Sicherheitsstandards</strong> wünschen, sind
				unsere <a href="/c/wandtresore">Wandtresore </a>die ideale Lösung. Sie sind auf den
				ersten Blick nicht sichtbar und so im Mauerwerk verankert, dass es quasi unmöglich
				ist, sie mitzunehmen. Wenn Sie nicht die Möglichkeit haben, einen solchen{" "}
				<a href="/c/wertschutzschraenke">Wertschutzschrank</a> zu installieren – keine
				Sorge. Auch mit unseren <a href="/c/moebeltresore">Möbeltresoren </a>, die Sie
				beispielsweise in einem <a href="/c/schranktresore">Schrank </a> verschrauben
				können, sind Ihre Wertsachen optimal geschützt.
			</p>

			<h4>Sicherheit ist bei Waffen besonders wichtig</h4>
			<p>
				Auch Sportschützen, Jäger oder Polizisten können bei uns{" "}
				<strong>spezielle Waffentresore</strong> kaufen, um sowohl{" "}
				<a href="/c/langwaffenschrank">Langwaffen</a> als auch
				<a href="/c/kurzwaffentresor"> Kurzwaffen</a> zu verwahren. Mit
				<a href="/c/waffenschraenke"> Waffenschränken</a> aus dem Tresoro-Shop lagern Sie
				Schusswaffen gesetzeskonform, einbruchsicher und zuverlässig.
			</p>

			<h4>Dokumente und Daten feuerfest aufbewahren</h4>
			<p>
				Mit <a href="/c/feuerschutztresore">feuerfesten Tresoren</a> schützen Sie wertvolle{" "}
				<a href="/c/feuerfeste-aktenschraenke">Akten</a> und{" "}
				<a href="/c/datentresore">Datenträger</a> nicht nur vor Diebstahl, sondern auch vor
				Brandschäden. Diese Tresore sind{" "}
				<strong>von unabhängigen Instituten zertifiziert</strong> und sorgen für bis zu 180
				Minuten dafür, dass Hitze ihrem Inhalt nichts anhaben kann. Selbstverständlich sind
				sie auch so gebaut, dass kein Löschwasser eindringen kann.
			</p>

			<h4>Tresor kaufen bei Tresoro: Die beste Wahl</h4>
			<p>
				Beim Kauf eines Tresors nimmt man nicht einfach irgendetwas - die persönlichen
				Wertgegenstände sollen schließlich in größtmöglicher Sicherheit aufbewahrt werden!
				Ob als <a href="/c/dokumententresore">Safe für Dokumente</a>, als Waffenschrank oder
				Mini-Tresor: Bei Tresoro finden Sie eine{" "}
				<strong>große Auswahl an geprüften und zertifizierten Qualitätstresoren </strong>{" "}
				von renommierten <a href="/marken">Tresor-Herstellern</a> wie etwa{" "}
				<a href="/marken/burg-waechter">Burg Wächter</a>.
			</p>

			<h4>Tresore kaufen in Berlin, Braunschweig, Hannover &amp; Co.</h4>
			<p>
				Vom leicht zu verbergenden <a href="/c/bodentresore">Bodentresor</a> oder Wandtresor
				bis zum großen Waffenschrank mit integriertem Munitionssafe: Im Tresoro-Shop können
				Sie für jeden Zweck einen
				<a href="/c/einbruchsichere-tresore"> Tresor</a> kaufen. Dass zum Beispiel
				Waffenschränke der Norm EN 1143-1 entsprechen, ist dabei selbstverständlich. Damit
				Ihr Tresor schnell den Weg an seinen Bestimmungsort findet, liefern wir in ganz
				Deutschland (ohne Inseln) <strong>kostenlos bis Bordsteinkante</strong> - egal, ob
				Sie sich für einen Mini-Tresor mit 1 Kilo Gewicht oder ein Modell mit 2 Tonnen
				entscheiden. Auch der Lieferort spielt keine Rolle: Wir liefern Tresore nach{" "}
				<a href="/content/tresor-kaufen-in-berlin">Berlin</a> ebenso wie nach{" "}
				<a href="/content/tresor-kaufen-in-muenchen">München</a>,
				<a href="/content/tresor-kaufen-in-hannover"> Hannover</a>,
				<a href="/content/tresor-kaufen-in-stuttgart"> Stuttgart</a> oder in einen ganz
				anderen Ort . Das gilt natürlich genauso für das bei uns erhältliche Tresor-Zubehör
				wie zum Beispiel{" "}
				<a href="/p/zubehoer-safe-dry-entfeuchter-fuer-schraenke-und-tresore">
					Entfeuchter
				</a>
				,{" "}
				<a href="/p/zubehoer-led-tresorleuchte-mit-bewegungsmelder">
					Tresorleuchten mit Bewegungsmelder
				</a>{" "}
				oder <a href="/c/feuerfeste-dokumententaschen">Dokumententaschen</a>. Gegen einen
				geringen Aufpreis liefern wir den Tresor auch direkt an den Ort der Verwendung und
				stellen ihn dort auf bzw. verankern ihn. Sollten Sie nicht zufrieden sein, können
				Sie innerhalb von 14 Tagen von Ihrem Rückgaberecht Gebrauch machen.
			</p>

			<h4>Tresor-Hersteller: Burg Wächter, Secureo und viele mehr</h4>
			<p>
				Das Tresoro-Sortiment umfasst mehr als 1500 Qualitätstresore. Hier finden Sie für
				jeden Zweck und jede Größenordnung ein passendes Modell. Alle Tresormodelle, die Sie
				bei Tresoro kaufen können, werden von unabhängigen Stellen geprüft und kommen von
				international <a href="/marken">renommierten Marken</a>. Hier unsere Anbieter im
				Überblick: <a href="/marken/bordogna">Bordogna S.p.A.</a>,{" "}
				<a href="/marken/burg-waechter">Burg Wächter</a>,{" "}
				<a href="/marken/chubbsafes">Chubb Safes</a>,{" "}
				<a href="/marken/format">Format Tresore</a>,{" "}
				<a href="/marken/mueller-safe">Müller Safe</a>,{" "}
				<a href="/marken/phoenix-safe">Phoenix Safe</a>,{" "}
				<a href="/marken/secureo"> Secureo</a>,{" "}
				<a href="/marken/sentry-safe">Sentry Safe</a>,{" "}
				<a href="/marken/sistec">Sistec Tresore</a>,{" "}
				<a href="/marken/wertheim">Wertheim Tresore</a>. Welche Art Safe zu empfehlen ist,
				hängt dabei ganz davon ab, <strong>welchen Zweck der Tresor haben soll</strong>. In
				einem <a href="/c/wertschutzschraenke">Tresor für Zuhause </a> können Sie
				beispielsweise Ihre Dokumente und Wertgegenstände sichern, während Sie als
				Waffenbesitzer vielleicht Interesse an einem hochwertigen Waffenschrank oder{" "}
				<a href="/c/munitionsschrank">Munitionsschrank</a> haben.
			</p>

			<h4>Der Tresor als Waffenschrank</h4>
			<p>
				Vor allem Sportschützen, Jäger und Polizisten dürften sich für Waffenschränke
				interessieren. Sowohl Langwaffen als auch Kurzwaffen müssen schließlich auf{" "}
				<a href="/content/neues-waffengesetz-2017">gesetzeskonforme</a> und einbruchsichere
				Weise verwahrt werden. Der Besitz und das Handling von Waffen aller Art ist eine
				Angelegenheit, mit der viel Verantwortung einhergeht - Sicherheit ist da zweifellos
				ein Muss. Tresoro-Waffenschränke sind <strong>zertifiziert nach EN 1143-1</strong>,
				der Europanorm für alle neu produzierten und im Handel erhältlichen Modelle. Für den
				Fall einer Kontrolle durch die Waffenbehörde tragen die Tresore eine entsprechende
				Prüfplakette. Ebenso wichtig wie der Waffentresor selbst ist übrigens die
				Munitionsbox zur sicheren Verwahrung der Munition im Tresor.
			</p>

			<h4>Feuerfester Tresor für noch mehr Sicherheit</h4>
			<p>
				Ein erheblicher Schaden kann nicht nur durch Einbruch, sondern auch durch Feuer
				entstehen - es sei denn, Sie schützen Ihre Wertgegenstände in einem{" "}
				<a href="/c/feuerfeste-tresore">feuerfesten Tresor</a>! Dabei gibt es verschiedene{" "}
				<a href="/tresor-sicherheitsstufen">Abstufungen von Hitzeschutz</a>. Den höchsten
				Feuerschutz bieten Modelle, bei denen der Tresorinhalt{" "}
				<strong>über 100 Minuten</strong> lang auch bei Temperaturen von{" "}
				<strong>mehr als 1000°C</strong> unversehrt bleibt. Bei einem Dokumentensafe zum
				Beispiel erkennen Sie die Sicherheitsstufe am Zertifikat: P steht für Brandschutz
				von Papierdokumenten, Akten oder Sparbüchern bis zu 150°C. übersteigt die
				Außentemperatur diese Marke, wird das Papier geschwärzt und unleserlich. Das
				Zertifikat DIS wiederum sagt aus, dass Speichermedien wie Disketten, Computerbänder
				oder CDs im Tresor bei bis zu 50°C brauchbar bleiben. Unabhängig vom Hitzegrad ist
				der Inhalt unserer feuerfesten Tresore aber natürlich auch vor Löschwasser und
				-schaum geschützt. Schließlich bringt der beste Brandschutz nur wenig, wenn die
				Löscharbeiten den eigentlichen Schaden verursachen!
			</p>

			<h4>Sicherheitsstufen bei Tresoren</h4>
			<p>
				Man unterscheidet bei Tresoren zwischen verschiedenen{" "}
				<a href="/tresor-sicherheitsstufen">Sicherheitsstufen</a>. Der Sicherheitsgrad
				spielt unter anderem dann eine Rolle, wenn es um Schadensersatz durch die
				Versicherung geht: Die <strong>Klassifizierung des Tresors</strong> entscheidet über
				die maximale Versicherungssumme. Wie widerstandsfähig ein Tresor eingestuft wird und
				mit welcher Rückerstattung man rechnen kann, hängt auch davon ab, ob das Modell
				privat oder gewerblich eingesetzt wird. Achtung: Bei günstigen Tresoren, also bei
				Modellen der Stufe A oder B nach VDMA-24992 (Verband Deutscher Maschinen- und
				Anlagenbauer), haben Sie keinen Versicherungsschutz. Bei einem Tresor der
				Sicherheitsstufe S1 oder S2 nach VdS EN 14450 im Privatgebrauch hingegen haben Sie
				immerhin einen Einbruchsschutz im Wert von bis zu 2500 Euro. Den höchsten Schutz und
				Versicherungssummen von 40.000 bis hin zu 750.000 Euro bieten{" "}
				<strong>Tresore der Sicherheitsstufe 0 bis 6 nach der Norm EN 1143-1</strong>. Falls
				Sie sich noch unsicher sind, welchen Sicherheitsgrad Ihr Tresor idealerweise haben
				sollte, helfen wir Ihnen ganz einfach bei der Suche. Kontaktieren Sie uns dazu gerne
				jederzeit. Eine Entscheidung ist auch gefragt, wenn es um das Format des Tresors
				gehen soll: Wie viel Platz soll Ihr Tresor einnehmen? Möchten Sie darin vielleicht
				sogar nur einzelne Schlüssel und Kreditkarten verwahren? Falls das der Fall ist,
				möchten wir Ihnen gerne unsere kleinsten Multitalente vorstellen: die Mini-Tresore.
			</p>

			<h4>Tresore im Kleinformat: der Mini-Tresor</h4>
			<p>
				Wertvolle Besitztümer müssen nicht immer groß sein. Ganz im Gegenteil -
				Kreditkarten, Schlüssel und Co. kommen im Kleinformat und wollen dennoch sicher
				verstaut werden. Für diese Fälle gibt es unsere Mini-Tresore: Hier können Sie
				Dokumente und Wertgegenstände{" "}
				<strong>einbruchsicher und vor allem unauffällig</strong> verwahren. Sie haben dabei
				die Wahl zwischen <a href="/c/steckdosentresore">Steckdosentresoren</a> und{" "}
				<a href="/c/rohrtresore">Rohrtresoren</a>. Beide Varianten haben ein sehr geringes
				Gewicht, das leichteste Modell wiegt sogar nur federleichte 800 Gramm. Am oberen
				Ende der Gewichtsskala liegen Modelle mit 4 Kilogramm. Beide Arten von Mini-Tresoren
				können in der Wand verbaut werden und sind dann nur noch als Safe zu erkennen, wenn
				man genau weiß, wo man suchen muss. In die kleinen Tresore passen je nach Design
				kleine Wertgegenstände, Reisedokumente, Bargeld, Kreditkarten oder Schlüssel. Auch
				leichter Brandschutz ist gegeben. Der große Vorteil dieser Tresormodelle im
				Kleinformat ist die Tatsache, dass sie sehr unauffällig sind. Wer nicht eingeweiht
				ist, wird einen Steckdosen- oder Rohrtresor kaum als solchen erkennen. Das macht den
				Mini-Tresor <strong>weitaus sicherer</strong> als das vermeintliche Geheimversteck
				in der Sockenschublade oder ganz hinten im Kleiderschrank.
			</p>

			<h4>Zahlenschloss und Fingerprintsensor: So sichern Sie Ihren Tresor</h4>
			<p>
				Der Sicherheitsgrad wird bei Tresoren maßgeblich auch durch die{" "}
				<strong>Art der Versperrung</strong> beeinflusst. Die Produkte aus dem
				Tresoro-Sortiment sind standardmäßig mit einem Doppelbartschloss ausgestattet und
				auf Wunsch mit einem alternativem Schloss erhältlich.
			</p>

			<h4>Tresore mit Zahlenschloss</h4>
			<p>
				Bei unseren Tresoren können Sie neben einem Elektronikschloss auch ein{" "}
				<strong>
					mechanisches Zahlenkombinationsschloss oder ein Doppelbartschloss mit zwei
					Schlüsseln{" "}
				</strong>{" "}
				wählen. Optional lassen sich die Tresore auch mit einem zusätzlichen{" "}
				<a href="/c/einbruchsichere-tresore?page=1&menu%5BlockType%5D=Fingerprintschloss">
					Fingerprintsensor{" "}
				</a>{" "}
				ausstatten Ein Fingerabdrucksensor ist äußerst praktisch, da bei dieser Variante ein
				Höchstmaß an Individualität gegeben ist. Natürlich können auch mehrere
				Fingerabdrücke registriert werden, doch abgesehen von den jeweiligen Personen kommt
				so schnell niemand an den Inhalt Ihres Tresors. Plus: Ein Fingerabdruck kann nicht
				„vergessen“ werden - anders als eine Zahlenkombination.
			</p>

			<h4>Kann man einen Tresor knacken?</h4>
			<p>
				Wer überlegt, einen Tresor zu kaufen, stellt sich früher oder später vermutlich auch
				die Frage, ob sich das lohnt. Denn ist es nicht theoretisch möglich,{" "}
				<strong>einen Tresor zu knacken</strong>? Die einfache Antwort lautet: Ja, möglich
				ist es. Bei einem hochwertigen, fest verankerten und geprüften Tresor ist es aber
				auch alles andere als einfach. Tresore von Tresoro sind nicht nur zertifiziert und
				geprüft, sondern werden auch fest in der Wand oder im Boden verankert. Einen solchen
				Tresor schnell zu knacken, wäre aussichtslos, ebenso wie das Vorhaben, den Tresor
				wegzutragen. Die Investition in gute Befestigung und hochwertige Materialien zahlt
				sich also im Einbruchsfall definitiv aus.
			</p>
			<p>
				Vom Familienerbstück bis hin zum wichtigen Geschäftsdokument: Jeder Mensch hat den
				einen oder anderen Gegenstand, den er <strong>besonders gut schützen</strong> will.
				Im Sortiment von Tresoro finden Sie bestimmt genau den Safe, der zu Ihrem
				Verwendungsbudget und in Ihr Budget passt. überzeugen Sie sich einfach selbst und
				nutzen Sie unsere Filter, um Ihren perfekten Tresor zu finden!
			</p>
		</div>
	</>
)

const SEOTEXT = ({ countryCode }: Props) => {
	return (
		<Box my={5}>
			<Box mb={5} style={{ textAlign: "center" }}>
				<h3 style={{ textAlign: "center" }}>Tresor online kaufen</h3>
			</Box>
			<Container>{countryCode === "DE" ? <SEOTEXTDE /> : <SEOTEXTAT />}</Container>
		</Box>
	)
}

interface Props {
	countryCode: CountryCode
}

export default SEOTEXT
