import React from "react"
import Link from "components/i18n/Link"
import { Box, Container, Grid } from "@mui/material"
import { makeStyles } from "makeStyles"
import Button from "components/Buttons/Button"

import DiscoverProductsSVG from "svg/homepage/discover-products.svg"

const useStyles = makeStyles()(() => ({
	background: {
		background: "#F4F4F8",
		width: "70%",
		height: "100%",

		position: "absolute",
		left: 0,
		top: 0,
		zIndex: -1,
		"@media only screen and (max-width: 960px)": {
			height: 440,
			width: "100%",
		},
	},
	safeImage: {
		display: "block",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
}))

const DiscoverProducts = () => {
	const { classes } = useStyles()

	return (
		<Box my={4} py={3} style={{ position: "relative", height: "auto", marginBottom: 50 }}>
			<div className={classes.background} />
			<Container style={{ zIndex: 1 }}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<h1>Über 1500 Tresore und Waffenschränke günstig kaufen!</h1>
						<p>
							Ob für Firmen oder für Zuhause – der <strong>Tresoro-Shop</strong>{" "}
							bietet zuverlässige und sichere Aufbewahrungsmöglichkeiten für{" "}
							<strong>Dokumente, Datenträger, Waffen und Wertgegenstände</strong>{" "}
							aller Art. Wir arbeiten mit renommierten Herstellern zusammen, die für
							allerhöchste <strong>Qualitäts- und Sicherheitsstandards</strong>{" "}
							stehen.
						</p>
						<p>
							Egal, was Sie vor Zugriff durch Unbefugte schützen möchten: Bei uns
							finden Sie immer die passende Lösung!
						</p>
						<Link
							href={`/c/einbruchsichere-tresore`}
							style={{ width: "100%", color: "white" }}
						>
							<Button color="secondary">Produkte entdecken</Button>
						</Link>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						style={{ textAlign: "right" }}
						className={classes.safeImage}
					>
						<DiscoverProductsSVG style={{ width: "80%", margin: "auto" }} />
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default DiscoverProducts
