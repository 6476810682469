import React from "react"
import { GetServerSideProps } from "next"
import dynamic from "next/dynamic"

import serializeJSON from "@secureo/common/utils/serializeJSON"
import { Category } from "@secureo/common/typings/Category"
import fetchBrandCategories from "@secureo/common/utils/commerceTools/graphql/queries/fetchBrandCategories"
import { anonymousAccessTokenCache } from "@secureo/common/utils/commerceTools/token/accessTokenCache"

import ATF from "containers/HomePage/ATF"
import Benefits from "containers/HomePage/Benefits"
import DiscoverProducts from "containers/HomePage/DiscoverProducts"
const Products = dynamic(() => import("containers/HomePage/Products"), {
	ssr: false,
})
// import WhyWithUs from "containers/HomePage/WhyWithUs"
const Brands = dynamic(() => import("containers/HomePage/Brands"), {
	ssr: false,
})
const Credentials = dynamic(() => import("containers/HomePage/Credentials"), {
	ssr: false,
})
import SeoText from "containers/HomePage/SeoText"
import getCountryCodeFromHostAndQueryParams from "utils/getCountryCodeFromHostAndQueryParams"
import { CountryCode } from "@secureo/common/typings/CountryCode"

import productsForHPSlider from "config/productsForHPSlider.json"
import fetchProductsBySkus, {
	SliderProduct,
} from "@secureo/common/utils/commerceTools/graphql/queries/fetchProductsBySkus"
import HomePageReviews from "containers/HomePage/HomePageReviews"

const HomePage = ({ brandCategories, products, countryCode }: Props) => {
	return (
		<>
			<ATF countryCode={countryCode} />
			<Benefits />
			<DiscoverProducts />
			<Products products={products} countryCode={countryCode} />
			{/* <WhyWithUs /> */}
			<Brands brandCategories={brandCategories} />
			<HomePageReviews />
			<Credentials countryCode={countryCode} />
			<SeoText countryCode={countryCode} />
		</>
	)
}

interface Props {
	brandCategories: Category[]
	locale: string
	products: { [sku: string]: SliderProduct }
	countryCode: CountryCode
}

export const getServerSideProps: GetServerSideProps = async (context) => {
	const { res } = context
	const accessToken = await anonymousAccessTokenCache()

	const host = context.req?.headers?.host ?? window?.location?.host
	const countryCode = getCountryCodeFromHostAndQueryParams(host, context.query) as CountryCode
	const locale = `de-${countryCode}`

	const productsForCountryCode = productsForHPSlider[countryCode]
	const productSkus = [...productsForCountryCode.offers, ...productsForCountryCode.popular]

	const [brandCategories, products] = await Promise.all([
		fetchBrandCategories(accessToken, locale, false, "TRE"),
		fetchProductsBySkus(accessToken, productSkus, "EUR"),
	])

	const props: Props = {
		brandCategories,
		products,
		locale,
		countryCode,
	}

	const oneHoursInSeconds = 60 * 60
	const oneDayInSeconds = oneHoursInSeconds * 24

	res.setHeader(
		"Cache-Control",
		`s-maxage=${oneHoursInSeconds}, stale-while-revalidate=${
			oneDayInSeconds * 31 - oneHoursInSeconds
		}`,
	)

	return {
		props: serializeJSON(props),
	}
}

export default HomePage
