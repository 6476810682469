import { useState, useEffect } from "react"
import Link from "components/i18n/Link"
import { Container, Box, Grid } from "@mui/material"
import { makeStyles } from "makeStyles"
import ItemsCarousel from "react-items-carousel"

import { HiChevronRight } from "react-icons/hi"
import { HiChevronLeft } from "react-icons/hi"
import { BsArrowRightShort } from "react-icons/bs"

import { CountryCode } from "@secureo/common/typings/CountryCode"

import imagesForSlider from "config/imagesForHPSlider.json"

const useStyles = makeStyles()(() => ({
	arrowButton: {
		opacity: 0,
		transition: "0.3s",
		background: "white",
		width: 28,
		height: 28,
		borderRadius: "50%",
		color: "black",
	},
	card: {
		width: "100%",
		borderRadius: 5,
		display: "flex",
		position: "relative",
		transition: "0.3s",
		"&:hover": {
			background: "rgba(0,0,0,0.5)",
			"& $arrowButton": {
				opacity: 1,
			},
		},
	},
	arrowButtonLeft: {
		padding: 5,
		marginLeft: 35,
		background: "none",
		borderRadius: 5,
		border: "none",
		boxShadow: "none",
		outline: "none",
		cursor: "pointer",
		"&:hover": {
			background: "whitesmoke",
		},
	},
	arrowButtonRight: {
		padding: 5,
		marginRight: 35,
		background: "none",
		borderRadius: 5,
		border: "none",
		boxShadow: "none",
		outline: "none",
		cursor: "pointer",
		"&:hover": {
			background: "whitesmoke",
		},
	},
	slide: {
		height: 355,
		"@media only screen and (max-width: 600px)": {
			height: "auto",
		},
	},
}))

const autoPlayDelay = 4000

const ATF = ({ countryCode }: Props) => {
	const { classes } = useStyles()

	const [activeItemIndex, setActiveItemIndex] = useState(0)
	const chevronWidth = 0

	const images = imagesForSlider[countryCode]

	useEffect(() => {
		const intervalId = setInterval(() => {
			setActiveItemIndex((activeItemIndex + 1) % images.length)
		}, autoPlayDelay)
		return () => clearInterval(intervalId)
	}, [activeItemIndex, images.length])

	return (
		<Container>
			<Box mt={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={7}>
						<Box
							style={{
								width: "100%",
								background: "#F4F4F8",
								borderRadius: 5,
							}}
							className={classes.slide}
						>
							<ItemsCarousel
								requestToChangeActive={setActiveItemIndex}
								activeItemIndex={activeItemIndex}
								numberOfCards={1}
								gutter={10}
								leftChevron={
									<button className={classes.arrowButtonLeft}>
										<HiChevronLeft size="1.8rem" />
									</button>
								}
								rightChevron={
									<button className={classes.arrowButtonRight}>
										<HiChevronRight size="1.8rem" />
									</button>
								}
								chevronWidth={chevronWidth}
								infiniteLoop={true}
								slidesToScroll={1}
								showSlither={false}
							>
								{images.map((sliderImage) => {
									const { name, link, image } = sliderImage
									return (
										<Link href={link} key={name}>
											<img
												alt={name}
												src={image}
												style={{
													width: "100%",
													objectFit: "cover",
												}}
												className={classes.slide}
											/>
										</Link>
									)
								})}
							</ItemsCarousel>
						</Box>
					</Grid>
					<Grid item xs={12} md={2}>
						<Link
							href={`/c/einbruchsichere-tresore`}
							style={{ width: "100%", color: "white" }}
						>
							<div
								style={{
									height: 170,
									marginBottom: 15,
								}}
								className={classes.card}
							>
								<img
									alt="einbruchsichere-tresore"
									src="https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1602497997/Tresoro%20Neu/Homepage/einbruchsichere-tresore.png"
									style={{
										height: 170,
										width: "100%",
										position: "absolute",
										zIndex: -1,
										objectFit: "cover",
									}}
								/>
								<Box pb={1} px={1} style={{ alignSelf: "flex-end", width: "100%" }}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											color: "white",
										}}
									>
										<div>
											Einbruchsichere <br />
											Tresore
										</div>
										<div className={classes.arrowButton}>
											<BsArrowRightShort size="1.7rem" />
										</div>
									</div>
								</Box>
							</div>
						</Link>
						<Link
							href={`/c/schluesselorganisation`}
							style={{ width: "100%", color: "white" }}
						>
							<div
								style={{
									height: 170,
								}}
								className={classes.card}
							>
								<img
									alt="schluessel-management"
									src="https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1602498029/Tresoro%20Neu/Homepage/schluessel-management.png"
									style={{
										height: 170,
										width: "100%",
										position: "absolute",
										zIndex: -1,
										objectFit: "cover",
									}}
								/>
								<Box pb={1} px={1} style={{ alignSelf: "flex-end", width: "100%" }}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											color: "white",
										}}
									>
										<div>
											Schlüssel <br /> Management
										</div>
										<div className={classes.arrowButton}>
											<BsArrowRightShort size="1.7rem" />
										</div>
									</div>
								</Box>
							</div>
						</Link>
					</Grid>
					<Grid item xs={12} md={3}>
						<Link href={`/c/waffenschraenke`} style={{ width: "100%", color: "white" }}>
							<div
								style={{
									height: 355,
								}}
								className={classes.card}
							>
								<img
									alt="waffenschraenke"
									src="https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1602498046/Tresoro%20Neu/Homepage/waffenschraenke.png"
									style={{
										height: 355,
										width: "100%",
										position: "absolute",
										zIndex: -1,
										objectFit: "cover",
									}}
								/>
								<Box p={2} style={{ alignSelf: "flex-end", width: "100%" }}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											color: "white",
										}}
									>
										<div>Zu den Waffenschränken</div>
										<div className={classes.arrowButton}>
											<BsArrowRightShort size="1.7rem" />
										</div>
									</div>
								</Box>
							</div>
						</Link>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

interface Props {
	countryCode: CountryCode
}

export default ATF
