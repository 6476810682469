import { useState } from "react"
import Image from "next/image"
import { Box, Container, Grid } from "@mui/material"
import { makeStyles } from "makeStyles"
import ItemsCarousel from "react-items-carousel"

import { HiChevronRight } from "react-icons/hi"
import { HiChevronLeft } from "react-icons/hi"

import TrustedShopsExellentShopAward from "images/TA-Excellent-Shop-5-Jahre-2023_TA-Excellent-Shop-5-Jahre-2023px.png"

const reviews: {
	authorAndCity: string
	rating: string
	text: string
}[] = [
	{
		authorAndCity: "Robert B., Hamburg",
		rating: "★★★★★",
		text: `Von der Bestellung über die Zahlungsabwicklung und
        der Lieferung waren es neun Tage. Die Kommunikation
        verlief zuvorkommend und die Ware wurde einwandfrei
        per Spedition geliefert. Hiermit spreche ich meine
        uneingeschränkte Empfehlung für Tresoro aus.`,
	},
	{
		authorAndCity: "Steffen K., Magdeburg",
		rating: "★★★★★",
		text: `Sehr gute Kaufabwicklung. Turboversand. Der
        Verkäufer hat eine sehr gute Arbeit geleistet und
        ich als Kunde wurde stets informiert. Der Preis war
        natürlich auch sehr gut. Sehr zu empfehlen.`,
	},
	{
		authorAndCity: "Stefan K., Oberhausen",
		rating: "★★★★★",
		text: `Sehr freundlich und hilfsbereit. Vorbildlicher
        Service. Tolle Mitarbeiter bei der Service Hotline.
        Vielen Dank.`,
	},
	{
		authorAndCity: "Rainer S., Benningen",
		rating: "★★★★★",
		text: `Einer der besten Onlinefirmen, die ich kennengelernt
        habe. Top Service. Es wurde verschiedentlich nach
        meinen Vorstellungen gefragt und betreffend der
        Lieferung. Hoher Servicegrad. Schnelle Abwicklung.
        Kundennähe, die man so nicht online kennt.`,
	},
	{
		authorAndCity: "Nico V., Schwäbisch Gmünd",
		rating: "★★★★★",
		text: `Super Ware zu sehr guten Preisen. Gute
        Kommunikation, immer erreichbar, freundlich und
        hilfsbereit. Würde immer wieder hier bestellen.
        Kleiner Tipp für diejenigen die sich Sorgen machen
        wegen Versand und Shop aus Österreich. Mein Tresor
        kam direkt von Burg Wächter aus Deutschland.`,
	},
	{
		authorAndCity: "Mike F., Vogelsbergkreis",
		rating: "★★★★★",
		text: `Kommunikation und Terminabstimmung per mail / SMS
        gut. Spedition kam kurz vor Ende des avisierten
        Zeitraums. Fahrer freundlich, Ware gut, Verpackung
        gut. Preis super. Ich bin rundum zufrieden.`,
	},
	{
		authorAndCity: "Manuel J., Obersimten",
		rating: "★★★★★",
		text: `Top Artikel ich bin sehr zufrieden, vor allem zu
        diesem unschlagbaren Preis. Schnelle und ordentliche
        Lieferung, gerne wieder.`,
	},
	{
		authorAndCity: "Jörg W., Senden",
		rating: "★★★★★",
		text: `Top Artikel ich bin sehr zufrieden, vor allem zu
        diesem unschlagbaren Preis. Schnelle und ordentliche
        Lieferung, gerne wieder.`,
	},
	{
		authorAndCity: "Burkhard L., Gönnheim",
		rating: "★★★★★",
		text: `Schneller Versand. Die Ware ist einwandfrei. Alles
        bestens. Neutrale Verpackung. Ich würde wieder bei
        Tresoro kaufen. Danke.`,
	},
	{
		authorAndCity: "Rico B., Dresden",
		rating: "★★★★★",
		text: `Schnelle Lieferung, erstklassige Ware, sehr gute
        Korrespondenz aller Beteiligten. Wir kaufen sicher
        wieder bei Tresoro.`,
	},
]

const useStyles = makeStyles()(() => ({
	arrowButtonLeft: {
		padding: 5,
		background: "white",
		borderRadius: 5,
		border: "2px solid whitesmoke",
		boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.15)",
		outline: "none",
		"&:hover": {
			background: "whitesmoke",
		},
	},
	arrowButtonRight: {
		padding: 5,
		background: "white",
		borderRadius: 5,
		border: "2px solid whitesmoke",
		boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.15)",
		outline: "none",
		"&:hover": {
			background: "whitesmoke",
		},
	},
	"@media only screen and (max-width: 600px)": {
		arrowButtonRight: {
			display: "none",
		},
		arrowButtonLeft: {
			display: "none",
		},
	},
	slide: {
		borderRadius: 10,
		boxShadow: "0 0px 10px rgba(0, 0, 0, 0.1)",
		margin: "5px 10px",
		background: "white",
		padding: 30,
		minHeight: 150,
	},
}))

const HomePageReviews = () => {
	const { classes } = useStyles()
	const [activeItemIndex, setActiveItemIndex] = useState(0)
	const chevronWidth = 0

	return (
		<Box py={4} style={{ background: "#F4F4F8", overflowX: "hidden", overflowY: "hidden" }}>
			<Container>
				<div style={{ textAlign: "center", fontSize: 18, fontWeight: 800 }}>
					Das sagen unsere Kunden
				</div>
				<Grid container spacing={3} mt={3}>
					<Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "center" }}>
						<Image
							src={TrustedShopsExellentShopAward}
							alt="Trusted Shops Award"
							height={200}
							style={{ display: "block", margin: "0 auto" }}
						/>
					</Grid>
					<Grid item xs={12} sm={8}>
						<div
							style={{
								maxWidth: 700,
								marginLeft: "auto",
								marginRight: "auto",
								position: "relative",
							}}
						>
							<img
								alt="quote-start"
								src="https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1616499394/Tresoro%20Neu/ContentGraphics/QuoteStart.png"
								style={{ position: "absolute", bottom: -60, left: -80 }}
							/>
							<img
								alt="quote-end"
								src="https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1616499394/Tresoro%20Neu/ContentGraphics/QuoteStart.png"
								style={{
									position: "absolute",
									top: -60,
									right: -80,
									transform: "rotate(180deg)",
								}}
							/>
							<Box my={3} style={{ padding: `0 ${chevronWidth}px` }}>
								<ItemsCarousel
									requestToChangeActive={setActiveItemIndex}
									activeItemIndex={activeItemIndex}
									numberOfCards={1}
									gutter={10}
									leftChevron={
										<button className={classes.arrowButtonLeft}>
											<HiChevronLeft size="1.8rem" />
										</button>
									}
									rightChevron={
										<button className={classes.arrowButtonRight}>
											<HiChevronRight size="1.8rem" />
										</button>
									}
									outsideChevron
									chevronWidth={chevronWidth}
									infiniteLoop={true}
									slidesToScroll={1}
									showSlither={false}
								>
									{reviews.map((review) => {
										const { authorAndCity, rating, text } = review

										return (
											<div key={authorAndCity} className={classes.slide}>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "space-between",
													}}
												>
													<div>
														<strong>{authorAndCity}</strong>
													</div>
													<div>{rating}</div>
												</div>
												<p>{text}</p>
											</div>
										)
									})}
								</ItemsCarousel>
							</Box>
						</div>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default HomePageReviews
