import React from "react"
import { Container, Box, Hidden } from "@mui/material"
import { makeStyles } from "makeStyles"
import Link from "components/i18n/Link"

import { RiShieldUserFill, RiBillFill } from "react-icons/ri"
import { ImHeadphones } from "react-icons/im"
import { FaTruckMoving, FaTags, FaThumbsUp, FaBuilding } from "react-icons/fa"
import { BiPackage } from "react-icons/bi"
import { BsTools } from "react-icons/bs"

const useStyles = makeStyles()(() => ({
	paper: {
		boxShadow: "none",
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
	},
}))

const items = [
	{
		headline: "100% Datenschutz",
		text: "DSGVO konformer Schutz",
		icon: <RiShieldUserFill size="4vh" color="#093A3E" style={{ marginRight: 15 }} />,
		url: "/content/datenschutz",
	},
	{
		headline: "Gratis Lieferung",
		text: "Frei Bordsteinkante",
		icon: <FaTruckMoving size="4vh" color="#093A3E" style={{ marginRight: 15 }} />,
		url: "/lieferung-montage",
	},
	{
		headline: "Kompetente Beratung",
		text: "Fragen Sie unsere Experten",
		icon: <ImHeadphones size="4vh" color="#093A3E" style={{ marginRight: 15 }} />,
		url: "/kontakt",
	},
	{
		headline: "2% Vorkassen Rabatt",
		text: "2% Rabatt auf Ihren Einkauf",
		icon: <FaTags size="4vh" color="#093A3E" style={{ marginRight: 15, marginTop: -4 }} />,
		url: "/content/bezahlung",
	},
]

const additionalItems = [
	{
		headline: "Preis/-Leistung",
		text: "",
		icon: <FaThumbsUp size="4vh" color="#093A3E" style={{ marginRight: 15 }} />,
		url: "/content/warum-mit-uns",
	},
	{
		headline: "Kauf auf Rechnung",
		text: "",
		icon: <RiBillFill size="4vh" color="#093A3E" style={{ marginRight: 15 }} />,
		url: "/bezahlung",
	},
	{
		headline: "Riesige Auswahl",
		text: "",
		icon: <BiPackage size="4vh" color="#093A3E" style={{ marginRight: 15 }} />,
		url: "/marken",
	},
	{
		headline: "Montageservice",
		text: "",
		icon: <BsTools size="4vh" color="#093A3E" style={{ marginRight: 15 }} />,
		url: "/lieferung-montage",
	},
	{
		headline: "Gewerbekunden",
		text: "",
		icon: <FaBuilding size="4vh" color="#093A3E" style={{ marginRight: 15 }} />,
		url: "/content/gewerbekunden",
	},
]

const AboutUs = () => {
	const { classes } = useStyles()
	return (
		<Container>
			<Hidden smDown>
				<Box
					mt={3}
					py={3}
					px={1}
					className="scrollX-no-scrollbar"
					style={{
						background: "white",
						borderRadius: 10,
						overflowX: "scroll",
					}}
				>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						{items.map((item) => {
							const { headline, text, icon, url } = item
							return (
								<Link key={item.headline} href={url}>
									<Box style={{ width: "auto" }} p={2} className={classes.paper}>
										{icon}
										<div>
											<strong style={{ color: "#093A3E" }}>{headline}</strong>
											<div style={{ color: "grey", fontSize: 14 }}>
												{text}
											</div>
										</div>
									</Box>
								</Link>
							)
						})}
					</div>
				</Box>
			</Hidden>
			<Hidden smUp>
				<Box mt={3}>
					{items.map((item) => {
						const { headline, icon, url } = item

						return (
							<Link key={item.headline} href={url}>
								<Box
									p={2}
									mb={1}
									className={classes.paper}
									style={{ background: "#F4F4F8", borderRadius: 10 }}
								>
									{icon}
									<div>
										<span style={{ color: "#093A3E" }}>{headline}</span>
									</div>
								</Box>
							</Link>
						)
					})}
					{additionalItems.map((item) => {
						const { headline, text, icon, url } = item

						return (
							<Link key={item.headline} href={url}>
								<Box
									p={2}
									mb={1}
									className={classes.paper}
									style={{ background: "#F4F4F8", borderRadius: 10 }}
								>
									{icon}
									<div>
										<span style={{ color: "#093A3E" }}>{headline}</span>
									</div>
								</Box>
							</Link>
						)
					})}
				</Box>
			</Hidden>
		</Container>
	)
}

export default AboutUs
